import type { ConfigRoutes } from "config/types";
import lazyWithPreload from 'utility/lazyWithPreload';
import retry from 'utility/retryPromise';
import AuthRoot from 'root/authRoot/AuthRoot';
import SignInPage from 'pages/auth/sign-in';
import RestaurantsRoot from 'root/selectedMarketRoot/restaurantsRoot/RestaurantsRoot';
import SelectedMarketRoot from 'root/selectedMarketRoot/SelectedMarketRoot';
import MarketsRoot from 'root/selectedMarketRoot/marketsRoot/MarketsRoot';
import AccountsRoot from 'root/selectedMarketRoot/accountsRoot/AccountsRoot';
import DeviceKeysRoot from 'root/selectedMarketRoot/deviceKeysRoot/DeviceKeysRoot';

// Misc

const RequestPasswordResetPage = lazyWithPreload(() =>
  retry(() => import('pages/auth/reset'))
);

// Activate account

const SetPasswordPage = lazyWithPreload(() =>
  retry(() => import('pages/auth/activate/set-password'))
);
const EnterCodePage = lazyWithPreload(() =>
  retry(() => import('pages/auth/activate/start'))
);

// Expired invite

const ExpiredInvite = lazyWithPreload(() =>
  retry(() => import('pages/auth/activate/expired-invite'))
);

// MFA

const VerifyCodeMFA = lazyWithPreload(() =>
  retry(() => import('pages/auth/verify-code'))
);

const EnableMFA = lazyWithPreload(() =>
  retry(() => import('pages/auth/enable-mfa'))
);

// Home

const HomePage = lazyWithPreload(() =>
  retry(() => import('../pages/homePage/HomePage'))
);

// Lsm Root

const LsmRoot = lazyWithPreload(() =>
  retry(() => import('../root/selectedMarketRoot/lsmRoot/LsmRoot'))
);

// Lsm Settings

const LsmSettingsHomePage = lazyWithPreload(() =>
  retry(() => import('../pages/lsmSettings/homePage/HomePage'))
);

// Lsm Bundles
const ListLsmBundlesPage = lazyWithPreload(() =>
  retry(() => import('../pages/lsmBundles/listPage/ListPage'))
);

const DetailLsmBundlePage = lazyWithPreload(() =>
  retry(() => import('../pages/lsmBundles/detailPage/DetailPage'))
);

const CreateLsmBundlePage = lazyWithPreload(() =>
  retry(() => import('../pages/lsmBundles/createPage/CreatePage'))
);

// Products Root

const ProductsRoot = lazyWithPreload(() =>
  retry(() => import('../root/selectedMarketRoot/productsRoot/ProductsRoot'))
);

// Products Settings

const ProductsSettingsHomePage = lazyWithPreload(() =>
  retry(() => import('../pages/productsSettings/homePage/HomePage'))
);

const ProductsSettingsSourcePage = lazyWithPreload(() =>
  retry(() => import('../pages/productsSettings/sourcePage/SourcePage'))
);

const ProductsSettingsCreateSourcePage = lazyWithPreload(() =>
  retry(() =>
    import('../pages/productsSettings/createSourcePage/CreateSourcePage')
  )
);

const ProductsSettingsSourceDetailsTab = lazyWithPreload(() =>
  retry(() =>
    import('../pages/productsSettings/sourcePage/detailsTab/DetailsTab')
  )
);

const ProductsSettingsSourceUpdatesTab = lazyWithPreload(() =>
  retry(() =>
    import('../pages/productsSettings/sourcePage/updatesTab/UpdatesTab')
  )
);

// Product Items

const ListProductsPage = lazyWithPreload(() =>
  retry(() => import('../pages/products/listPage/ListPage'))
);

const DetailProductPage = lazyWithPreload(() =>
  retry(() => import('../pages/products/detailPage/DetailPage'))
);

const CreateProductPage = lazyWithPreload(() =>
  retry(() => import('../pages/products/createPage/CreatePage'))
);

// Product Categories

const ListProductCategoriesPage = lazyWithPreload(() =>
  retry(() => import('../pages/productCategories/listPage/ListPage'))
);

const DetailProductCategoryPage = lazyWithPreload(() =>
  retry(() => import('../pages/productCategories/detailPage/DetailPage'))
);

const CreateProductCategoryPage = lazyWithPreload(() =>
  retry(() => import('../pages/productCategories/createPage/CreatePage'))
);

// Product Allergens

const ListProductAllergensPage = lazyWithPreload(() =>
  retry(() => import('../pages/productAllergens/listPage/ListPage'))
);

const DetailProductAllergenPage = lazyWithPreload(() =>
  retry(() => import('../pages/productAllergens/detailPage/DetailPage'))
);

const CreateProductAllergenPage = lazyWithPreload(() =>
  retry(() => import('../pages/productAllergens/createPage/CreatePage'))
);

// Product Nutrients

const ListProductNutrientsPage = lazyWithPreload(() =>
  retry(() => import('../pages/productNutrients/listPage/ListPage'))
);

const DetailProductNutrientPage = lazyWithPreload(() =>
  retry(() => import('../pages/productNutrients/detailPage/DetailPage'))
);

const CreateProductNutrientPage = lazyWithPreload(() =>
  retry(() => import('../pages/productNutrients/createPage/CreatePage'))
);

// Assets Root

const AssetsRoot = lazyWithPreload(() =>
  retry(() => import('../root/selectedMarketRoot/assetsRoot/assetsRoot'))
);

// Assets

const ListAssetsPage = lazyWithPreload(() =>
  retry(() => import('../pages/mop/listPage/ListPage'))
);

const ListMopCategoryImagesPage = lazyWithPreload(() =>
  retry(() => import('../pages/mop/mopCategoryImages/listPage/ListPage'))
);

const ListMopProductImagesPage = lazyWithPreload(() =>
  retry(() => import('../pages/mop/mopProductsImages/listPage/ListPage'))
);

const DetailAssetsPage = lazyWithPreload(() =>
  retry(() => import('../pages/mop/detailPage/DetailPage'))
);

// Restaurants

const ListRestaurantsPage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurants/listPage/ListPage'))
);

const CreateRestaurantPage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurants/createPage/CreatePage'))
);

const DetailRestaurantPage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurants/detailPage/DetailPage'))
);

const DetailTagOverridePage = lazyWithPreload(() =>
  retry(() =>
    import(
      '../pages/restaurants/detailPage/tabs/tagOverride/DetailPage/DetailPage'
    )
  )
);

const RestaurantsOrderSettingsPage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurants/detailPage/tabs/orderSettings/Page'))
);

const RestaurantsSettingsHomePage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurantsSettings/home/Home'))
);

const RestaurantsSettingsCreateSourcePage = lazyWithPreload(() =>
  retry(() => import('../pages/restaurantsSettings/createSource/CreateSource'))
);

const RestaurantsSettingsSourceSettingsPage = lazyWithPreload(() =>
  retry(() =>
    import('../pages/restaurantsSettings/sourceSettings/SourceSettings')
  )
);

const RestaurantsDeliveryAreasPage = lazyWithPreload(() =>
  retry(() =>
    import('../pages/restaurants/deliveryService/Page')
  )
);

// Accounts

const ListAccountsPage = lazyWithPreload(() =>
  retry(() => import('../pages/accounts/listPage/ListPage'))
);

const EditAccountPage = lazyWithPreload(() =>
  retry(() => import('../pages/accounts/detailPage/DetailPage'))
);

const CreateAccountPage = lazyWithPreload(() =>
  retry(() => import('../pages/accounts/createPage/CreatePage'))
);

// Markets

const ListMarketsPage = lazyWithPreload(() =>
  retry(() => import('../pages/markets/listMarkets/ListMarkets'))
);

const EditMarketPage = lazyWithPreload(() =>
  retry(() => import('../pages/markets/editMarket/EditMarket'))
);

const CreateMarketPage = lazyWithPreload(() =>
  retry(() => import('../pages/markets/createMarket/CreateMarket'))
);

// UrlBuilder

const UrlBuilderRoot = lazyWithPreload(() =>
  retry(() =>
    import('../root/selectedMarketRoot/urlBuilderRoot/UrlBuilderRoot')
  )
);

const UrlBuilderHomePage = lazyWithPreload(() =>
  retry(() => import('../pages/urlBuilder/urlBuilderHome/UrlBuilderHome'))
);

// Tagging Tool
const ConsumerTagsRoot = lazyWithPreload(() =>
  retry(() => import('../pages/consumerTags/ConsumerTagsRoot'))
);

const ConsumerTagsPage = lazyWithPreload(() =>
  retry(() => import('../pages/consumerTags/ConsumerTagsPage'))
);

const ConsumerTagsSettings = lazyWithPreload(() =>
  retry(() => import('../pages/consumerTags/Settings'))
);

// Remote Config
const RemoteConfigRoot = lazyWithPreload(() =>
  retry(() => import('../pages/remoteConfig/RemoteConfigRoot'))
);

// Security

const SecurityHome = lazyWithPreload(() =>
  retry(() => import('../pages/security/SecurityHome'))
);

const CreateDeviceKeyPage = lazyWithPreload(() =>
  retry(() => import('../pages/security/createDeviceKey/CreateDeviceKey'))
);

const DeviceKeyDetailPage = lazyWithPreload(() =>
  retry(() => import('../pages/security/deviceKeyDetail/DeviceKeyDetail'))
);

// Code importer

const CodeImporterRoot = lazyWithPreload(() =>
  retry(
    () => import('../root/selectedMarketRoot/codeImporterRoot/CodeImporterRoot')
  )
);

const CodeImporterSettings = lazyWithPreload(() =>
  retry(() => import('../pages/codeImporter/Settings'))
);

const CodeImporterHomePage = lazyWithPreload(() =>
  retry(() => import('../pages/codeImporter/Home'))
);

/**
 * This config is used to render all the page routes. (See Root.js)
 * It is also used to generate the Breadcrumb component.
 */
const CONFIG_ROUTES: ConfigRoutes[] = [
  {
    key: 'public',
    type: 'container',
    path: '/auth',
    component: AuthRoot,
    routes: [
      {
        type: 'redirect',
        exact: true,
        from: '/auth',
        to: '/auth/sign-in',
      },
      {
        type: 'uncrumbed',
        path: '/auth/sign-in',
        component: SignInPage,
      },
      {
        type: 'uncrumbed',
        path: '/auth/verify-code',
        component: VerifyCodeMFA,
      },
      {
        type: 'uncrumbed',
        path: '/auth/enable-mfa',
        component: EnableMFA,
      },
      {
        type: 'uncrumbed',
        path: '/auth/reset',
        component: RequestPasswordResetPage,
      },
      {
        type: 'redirect',
        exact: true,
        from: '/auth/activate',
        to: '/auth/activate/start',
      },
      {
        type: 'uncrumbed',
        path: '/auth/activate/start',
        component: EnterCodePage,
        preload: [SetPasswordPage],
      },
      {
        type: 'uncrumbed',
        path: '/auth/activate/set-password',
        component: SetPasswordPage,
      },
      {
        type: 'uncrumbed',
        path: '/auth/activate/expired-invite',
        component: ExpiredInvite,
      },
    ],
  },
  {
    key: 'private',
    type: 'container',
    path: '/:selectedMarketId',
    component: SelectedMarketRoot,
    routes: [
      {
        type: 'redirect',
        exact: true,
        from: '/:selectedMarketId',
        to: '/:selectedMarketId/home',
      },
      // Home
      {
        type: 'uncrumbed',
        exact: true,
        path: '/:selectedMarketId/home',
        component: HomePage,
      },
      // Lsm
      {
        type: 'container',
        path: '/:selectedMarketId/local-marketing',
        component: LsmRoot,
        routes: [
          // Lsm Bundles
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/local-marketing',
            crumb: 'Local Marketing',
            component: ListLsmBundlesPage,
            preload: [DetailLsmBundlePage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/local-marketing/create',
            crumb: 'new bundle',
            component: CreateLsmBundlePage,
            preload: [ListLsmBundlesPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/local-marketing/bundle/:selectedLsmBundleId',
            crumb: ':selectedLsmBundleId',
            component: DetailLsmBundlePage,
          },
          //Lsm Setings
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/local-marketing/settings',
            crumb: 'Settings',
            component: LsmSettingsHomePage,
          },
        ],
      },
      // Products
      {
        type: 'container',
        path: '/:selectedMarketId/products',
        component: ProductsRoot,
        routes: [
          // Product Items
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/products',
            crumb: 'Products',
            component: ListProductsPage,
            preload: [DetailProductPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/create',
            crumb: 'new product',
            component: CreateProductPage,
            preload: [ListProductsPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/product/:selectedProductId',
            crumb: ':selectedProductId',
            component: DetailProductPage,
          },
          // Product Settings
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/products/settings',
            crumb: 'Settings',
            component: ProductsSettingsHomePage,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/products/settings/sources/create',
            crumb: 'Settings',
            component: ProductsSettingsCreateSourcePage,
          },
          {
            type: 'container',
            path: '/:selectedMarketId/products/settings/sources/source/:sourceLocale',
            component: ProductsSettingsSourcePage,
            routes: [
              {
                type: 'crumbed',
                exact: true,
                path: '/:selectedMarketId/products/settings/sources/source/:sourceLocale',
                crumb: ':sourceLocale',
                component: ProductsSettingsSourceDetailsTab,
              },
              {
                type: 'uncrumbed',
                path: '/:selectedMarketId/products/settings/sources/source/:sourceLocale/updates',
                component: ProductsSettingsSourceUpdatesTab,
              },
            ],
          },
          // Product Categories
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/categories',
            exact: true,
            crumb: 'categories',
            component: ListProductCategoriesPage,
            preload: [DetailProductCategoryPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/categories/create',
            crumb: 'new category',
            component: CreateProductCategoryPage,
            preload: [ListProductCategoriesPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/categories/category/:selectedProductCategoryId',
            crumb: ':selectedProductCategoryId',
            component: DetailProductCategoryPage,
          },
          // Product Allergens
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/allergens',
            exact: true,
            crumb: 'allergens',
            component: ListProductAllergensPage,
            preload: [DetailProductAllergenPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/allergens/create',
            crumb: 'new allergen',
            component: CreateProductAllergenPage,
            preload: [ListProductAllergensPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/allergens/allergen/:selectedProductAllergenId',
            crumb: ':selectedProductAllergenId',
            component: DetailProductAllergenPage,
          },
          // Product Nutrients
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/nutrients',
            exact: true,
            crumb: 'nutrients',
            component: ListProductNutrientsPage,
            preload: [DetailProductNutrientPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/nutrients/create',
            crumb: 'new nutrient',
            component: CreateProductNutrientPage,
            preload: [ListProductNutrientsPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/products/nutrients/nutrient/:selectedProductNutrientId',
            crumb: ':selectedProductNutrientId',
            component: DetailProductNutrientPage,
          },
        ],
      },
      // ASSETS
      {
        type: 'container',
        path: '/:selectedMarketId/mop',
        component: AssetsRoot,
        routes: [
          // Assets
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/mop',
            crumb: 'MOP',
            component: ListAssetsPage,
            preload: [ListMopCategoryImagesPage, ListMopProductImagesPage],
          },
          // Category Assets
          {
            type: 'crumbed',
            path: '/:selectedMarketId/mop/category-images',
            exact: true,
            crumb: 'Category Images',
            component: ListMopCategoryImagesPage,
            preload: [DetailAssetsPage],
          },
          // Product Assets
          {
            type: 'crumbed',
            path: '/:selectedMarketId/mop/product-images',
            exact: true,
            crumb: 'Product Images',
            component: ListMopProductImagesPage,
            preload: [DetailAssetsPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/mop/product-images/:assetId',
            crumb: 'Details',
            component: DetailAssetsPage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/mop/category-images/:assetId',
            crumb: 'Details',
            component: DetailAssetsPage,
          },
        ],
      },
      // Restaurants
      {
        type: 'container',
        path: '/:selectedMarketId/restaurants',
        component: RestaurantsRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/restaurants',
            crumb: 'restaurants',
            component: ListRestaurantsPage,
            preload: [DetailRestaurantPage, CreateRestaurantPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/create',
            crumb: 'new restaurant',
            component: CreateRestaurantPage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/delivery-service/areas',
            crumb: 'Delivery Service Areas',
            component: RestaurantsDeliveryAreasPage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/restaurant/:viewedRestaurantId',
            crumb: ':viewedRestaurantId',
            component: DetailRestaurantPage,
          },
          {
            type: 'uncrumbed',
            exact: true,
            path: '/:selectedMarketId/restaurants/restaurant/:viewedRestaurantId/tag-override/:facilityOverrideId',
            component: DetailTagOverridePage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/restaurant/:viewedRestaurantId/order-settings',
            crumb: 'Restaurants Order Settings',
            component: RestaurantsOrderSettingsPage,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/restaurants/settings',
            crumb: 'settings',
            component: RestaurantsSettingsHomePage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/settings/sources/create',
            crumb: 'new source',
            component: RestaurantsSettingsCreateSourcePage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/restaurants/settings/sources/source/:viewedLanguage',
            crumb: ':viewedLanguage',
            component: RestaurantsSettingsSourceSettingsPage,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/accounts',
        component: AccountsRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/accounts',
            crumb: 'accounts',
            component: ListAccountsPage,
            preload: [CreateAccountPage, EditAccountPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/accounts/create',
            crumb: 'new account',
            component: CreateAccountPage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/accounts/account/:accountId',
            crumb: ':accountId',
            component: EditAccountPage,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/settings',
        component: MarketsRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/settings',
            crumb: 'settings',
            component: ListMarketsPage,
            preload: [EditMarketPage, CreateMarketPage],
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/settings/create',
            crumb: 'create market',
            component: CreateMarketPage,
          },
          {
            type: 'crumbed',
            path: '/:selectedMarketId/settings/setting/:viewedMarketId',
            crumb: ':viewedMarketId',
            component: EditMarketPage,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/url-builder',
        component: UrlBuilderRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/url-builder',
            crumb: 'URL Builder',
            component: UrlBuilderHomePage,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/tagging-tool',
        component: ConsumerTagsRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/tagging-tool',
            crumb: 'Tagging Tool',
            component: ConsumerTagsPage,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/tagging-tool/settings',
            crumb: 'Settings',
            component: ConsumerTagsSettings,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/remote-config',
        component: RemoteConfigRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/remote-config',
            crumb: 'Remote Config',
            component: () => null,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/remote-config/menu',
            crumb: 'Navigation Menu',
            component: () => null,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/remote-config/menu-fallback',
            crumb: 'Menu (fallback)',
            component: () => null,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/remote-config/:category',
            crumb: ':category',
            component: () => null,
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/security',
        component: DeviceKeysRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/security/ios-keys/create',
            crumb: 'Add Device Key',
            component: CreateDeviceKeyPage,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/security/ios-keys/ios-key/:deviceKey',
            crumb: 'Device Key',
            component: DeviceKeyDetailPage,
          },
          {
            type: 'crumbed',
            exact: false,
            path: '/:selectedMarketId/security',
            crumb: 'Security',
            component: SecurityHome,
            preload: [CreateDeviceKeyPage],
          },
        ],
      },
      {
        type: 'container',
        path: '/:selectedMarketId/code-uploader',
        component: CodeImporterRoot,
        routes: [
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/code-uploader',
            crumb: 'Code Uploader',
            component: CodeImporterHomePage,
          },
          {
            type: 'crumbed',
            exact: true,
            path: '/:selectedMarketId/code-uploader/settings',
            crumb: 'Settings',
            component: CodeImporterSettings,
          },
        ],
      },
    ],
  },
  {
    key: '404',
    component: () => <h1>Page not found</h1>,
  },
];

export default CONFIG_ROUTES;
