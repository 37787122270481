import { getAuth, RecaptchaVerifier } from 'firebase/auth';

let INSTANCE: null | RecaptchaVerifier = null;

const RE_CAPTCHA_ID = 'G_RE_CAPTCHA';

/**
 * Creates recaptcha instance and returns it
 * */
export function appReCaptchaCreate(): null | RecaptchaVerifier {
  if (!INSTANCE) {
    INSTANCE = new RecaptchaVerifier(
      getAuth(),
      RE_CAPTCHA_ID,
      { size: 'invisible' },
    );
  }
  // @ts-ignore
  else if (INSTANCE.widgetId === null) {
    // @ts-ignore
    INSTANCE.container = window.document.getElementById(RE_CAPTCHA_ID);
  }

  return INSTANCE;
}

/**
 * Resets internal recaptcha state
 * to prevent cryptic unexplainable errors
 * */
export function appReCaptchaClear() {
  // @ts-ignore
  if (INSTANCE && INSTANCE.widgetId !== null) {
    INSTANCE.clear();
    // @ts-ignore
    INSTANCE.widgetId = null;
    // @ts-ignore
    INSTANCE.destroyed = false;
    // @ts-ignore
    INSTANCE.renderPromise = null;
    // @ts-ignore
    INSTANCE.container.textContent = '';
    // @ts-ignore
    INSTANCE.container = undefined;
  }
}

export function AppReCaptcha(): JSX.Element {
  return (
    <div id={RE_CAPTCHA_ID} className="g-recaptcha" />
  );
}
